import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from 'react'


export default function Navbar({ logo }) {
  const data = useStaticQuery( graphql`
    query Logo {
      allFile(filter: {sourceInstanceName: {eq: "imagesLogos"}}) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData (
                placeholder: NONE
              )
            }
          }
        }
      }
    }
  `)
  const logoFilename = 'logo_' + logo
  function isNameMatch(item) {
     return (item.node.name === logoFilename)
  }
  const file = data.allFile.edges.filter(isNameMatch)[0].node
  const logo_image = getImage(file)
  const logo_name = file.name
  return (
      <nav>
        <Link to="/">
          <GatsbyImage image={logo_image} alt={logo_name} />
        </Link>
        <div className="links">
          <Link to="/">home</Link>
          {/* <Link to="/projects">projects</Link> */}
          <Link to="/contact">contact</Link>
          <Link to="/about">about</Link>
        </div>
      </nav>
  )
}
