import React from 'react'
import Navbar from './Navbar'
import '../styles/global.css'
import { Helmet } from "react-helmet"

export default function Layout({ logo, children }) {
    const upperLogo = logo.charAt(0).toUpperCase() + logo.slice(1)
    var pageTitle = upperLogo
    if (logo === 'index') {
        pageTitle = 'Home';
    }
    return (
        <div className="layout">
            <Navbar logo={ logo }/>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{ pageTitle + " - Mad Studio" }</title>
            </Helmet>
            <div className="content">
                { children }
            </div>
            <footer>
                <p>Copyright 2022 Madeline Tartakovsky</p>
            </footer>
        </div>
    )
}

Layout.defaultProps = {
    logo: "default"
}
